import { Component, ComponentRef, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
import { EntityService } from '@app/entity/entity.service';
import { finalize } from 'rxjs/operators';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { EntityDTO, FullMembershipConfigurationDTO, LoginFeature } from '@app/models';
import { DropdownComponent } from '@app/shared';
import { DataGridComponent, DataGridConfiguration, CheckboxComponent } from '@app/shared';
import { Feature } from '@models/enum/membership';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'membership-settings',
  templateUrl: './membership-settings.component.html',
  styleUrls: ['./membership-settings.component.css']
})
export class MembershipSettingsComponent implements OnInit, OnDestroy {

  
  @ViewChild(DataGridComponent) dataGridComponent: DataGridComponent;
  @ViewChild("componentDimmer") componentDimmer: ElementRef;
  
  public membershipConfigDTO: FullMembershipConfigurationDTO;
  public loginFeatures: LoginFeature[] = null;
  public gridConfiguration: DataGridConfiguration<LoginFeature>;
  private rxSubscription = new Subscription();
  private fetchingComponentData: boolean;
  private notifierService = this.injector.get(NotifierService);
  public selectedEntity: EntityDTO;
  private loginFeaturesSubject = new BehaviorSubject<LoginFeature[]>(this.loginFeatures);

  @ViewChild("versionDropdown") private versionDropdown: DropdownComponent;
  public versionCollection: {id: string, name: string }[] = [
    {"id": "1", "name": "v1"},
    {"id": "2", "name": "v2"},
  ];

  constructor(
    private entityService: EntityService,
    private authService: AuthenticationService,
    private injector: Injector
  ) { 
    this.setupLoginFeaturesDataGrid();
  }

  ngOnInit(): void {
    this.selectedEntity = this.authService.getUserSelectedEntity();
    this.rxSubscription.add(
      this.loginFeaturesSubject.subscribe((data) => {
        this.loginFeatures = data;
      })
    );
    this.getMembershipConfigurations();
  }

  ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  getMembershipConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .getMembershipConfigurations()
          .pipe(finalize(() => {
            this.setComponentDimmerVisibility(false);
            if (this.versionDropdown) {
              this.versionDropdown.hide();
            }
            this.getLoginFeatures(this.membershipConfigDTO.loginFeaturesMandatory);
          }))
          .subscribe((data) => {
            if (data == null) {
              this.createMembershipConfigurations();
            } else {
              this.membershipConfigDTO = data;
            }
          })
      );
    }
  }

  createMembershipConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.setComponentDimmerVisibility(true);
      this.rxSubscription.add(
        this.entityService
          .createMembershipConfigurations(new FullMembershipConfigurationDTO(), true)
          .pipe(finalize(() => {
            this.setComponentDimmerVisibility(false);
            if (this.versionDropdown) {
              this.versionDropdown.hide();
            }
            this.getLoginFeatures(this.membershipConfigDTO.loginFeaturesMandatory);
          }))
          .subscribe((data) => {
            this.membershipConfigDTO = data;
          })
      );
    }
  }

  getLoginFeatures(loginFeaturesMandatory: string) {
    let result = [];
    let featuresList = Object.entries(Feature);
    for (let i = 0; i < featuresList.length; i++) {
      let code = featuresList[i][0];
      let name = featuresList[i][1];
      let mandatory = loginFeaturesMandatory != null && loginFeaturesMandatory != undefined ? loginFeaturesMandatory.indexOf(code) > -1 : false;
      let loginFeature = new LoginFeature(code, name, mandatory);
      result.push(loginFeature);
    }
    this.loginFeatures = cloneDeep(result);
    this.loginFeaturesSubject.next(this.loginFeatures);
  }

  isDisabled(): boolean {
    return !this.membershipConfigDTO || !this.membershipConfigDTO.enabled;
  }

  isDisabledLoginFeatures(): boolean {
    return !this.membershipConfigDTO || !this.membershipConfigDTO.enabled || !this.membershipConfigDTO.loginFeaturesEnabled;
  }

  onCheckboxChange(value: boolean) {
    this.save();
  }

  onChangeButtonIcon(value: string) {
    this.membershipConfigDTO.buttonIcon = value;
    this.save();
  }

  public statusBarLogoFileChange(event: EventTarget) {
    this.setComponentDimmerVisibility(true);

    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;

    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.entityService.updateMembershipConfigurationsUploadStatusBarLogo(formData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((membershipConfig) => {
        this.membershipConfigDTO = membershipConfig;
      });
  }

  public onClickStatusBarLogoFileRemove(event: Event) {
    event.stopPropagation();

    if (!this.membershipConfigDTO) {
      return;
    }

    this.setComponentDimmerVisibility(true);
    this.entityService.updateMembershipConfigurationsRemoveStatusBarLogo(this.membershipConfigDTO)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((appConfig) => {
        this.membershipConfigDTO = appConfig;
      });
  }

  public iconFileChange(event: EventTarget) {
    this.setComponentDimmerVisibility(true);

    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;

    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.entityService.uploadMembershipIcon(formData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((membershipConfig) => {
        this.membershipConfigDTO = membershipConfig;
      });
  }

  public onClickIconRemove(event: Event) {
    event.stopPropagation();

    this.setComponentDimmerVisibility(true);
    
    this.entityService.removeMembershipIcon()
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((appConfig) => {
        this.membershipConfigDTO = appConfig;
      });
  }


  public showStatusBarLogoRemoveBtn(): boolean {
    if (this.membershipConfigDTO && this.membershipConfigDTO.statusBarLogoUrl) {
      return true;
    }
    return false;
  }

  public showIconRemoveBtn(): boolean {
    if (this.membershipConfigDTO && this.membershipConfigDTO.iconUrl) {
      return true;
    }
    return false;
  }

  public versionDropdownObservable = (): Observable<any> => {
    return of(this.versionCollection);
  }

  public versionDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  public onSaveEvent(event: any) {
    this.save()
  }

  save() {
    this.setComponentDimmerVisibility(true);
    this.rxSubscription.add(
      this.entityService
        .updateMembershipConfigurations(this.membershipConfigDTO)
        .pipe(finalize(() => {
          this.setComponentDimmerVisibility(false);
          if (this.versionDropdown) {
            this.versionDropdown.hide();
          }
        }))
        .subscribe((data) => {
          this.membershipConfigDTO = data;
        })
    );
  }

  saveFromLoginFeatures() {
    let loginFeaturesStringArray = "";
    if (this.loginFeatures != null && this.loginFeatures != undefined && this.loginFeatures.length > 0) {
      for (let i = 0; i < this.loginFeatures.length; i++) {
        if (this.loginFeatures[i].mandatory) {
          if (loginFeaturesStringArray == '') {
            loginFeaturesStringArray = this.loginFeatures[i].code;
          } else {
            loginFeaturesStringArray += "," + this.loginFeatures[i].code;
          }
        }
      }
    }
    this.membershipConfigDTO.loginFeaturesMandatory = loginFeaturesStringArray;
    this.save();
  }

  private setupLoginFeaturesDataGrid(): void {
    this.gridConfiguration = new DataGridConfiguration(null, false, true, true, false, false, true, null, true);

    this.gridConfiguration.addColumn({
      index: 0,
      headerText: 'Name',
      displayPropertyName: 'name',
      columnGridWidth: 4,
      isSortable: false,
    });

    this.gridConfiguration.addColumn({
      index: 1,
      headerText: 'Mandatory',
      columnGridWidth: 1,
      isSortable: false,
      component: CheckboxComponent,
      componentResolver: (componentRef: ComponentRef<CheckboxComponent>, item: LoginFeature) => {
        const instance = componentRef.instance;
        instance.inputValue = item.mandatory;

        instance.onChangeValue.subscribe((value: boolean) => {
          item.mandatory = value;
          this.saveFromLoginFeatures();
        });
      }
    });
  }

  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement = $(this.componentDimmer.nativeElement).dimmer({
      duration: { show: 400, hide: 800 },
    });

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer("show");
    } else {
      dimmerElement.dimmer("hide");
      this.fetchingComponentData = false;
    }
  }

}
