export class LoginFeature {
    
    constructor(code: string, name: string, mandatory: boolean) {
        this.code = code;
        this.name = name;
        this.mandatory = mandatory;
    }

    code: string;
    
    name: string;

    mandatory: boolean;
}
