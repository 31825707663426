import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ElementRef, Injector } from '@angular/core';
import { EntityService } from '@app/entity/entity.service';
import { ListingsService } from '@app/listings/listings.service';
import { AuthenticationService } from '@app/core/authentication.service';
import { Observable, of, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  AppConfigurationDTO, DatesDTO,
  AppLayout, AirportListingDTO, EntityType, CurrencyDTO, TimezoneListingDTO, CurrencyListingDTO
} from '@app/models';
import {
  DropdownComponent,
  MultipleSelectDropdownComponent,
  PopupComponent, PopupConfig
} from '@app/shared';
import { NotifierService } from '@app/core/notifications/notifier.service';
import { CurrencyDropdownComponent } from '@shared/components/currency-dropdown/currency-dropdown.component';
import { TimezoneDropdownComponent } from '@shared/components/timezone-dropdown/timezone-dropdown.component';

@Component({
  selector: 'app-settings',
  templateUrl: 'app-settings.component.html',
  styleUrls: ['app-settings.component.css']
})
/** app-settings component*/
export class AppSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

  public appConfigurationDto: AppConfigurationDTO;
  public datesExportGuestData: DatesDTO = new DatesDTO();

  // > Dimmers
  @ViewChild('componentDimmer') componentDimmer: ElementRef;
  private fetchingComponentData: boolean;
  public appLayoutList: any[];
  public airportsList: AirportListingDTO[];
  public disabledAirportGrid: boolean = false;

  @ViewChild(MultipleSelectDropdownComponent) multipleSelectDropdownComponent: MultipleSelectDropdownComponent;
  @ViewChild(PopupComponent) popupComponent: PopupComponent;


  public popupConfiguration: PopupConfig;

  // All Subscriptions that should be disposed on destroy
  private rxSubscription = new Subscription();

  private notifierService = this.injector.get(NotifierService);

  public currenciesList: CurrencyListingDTO[];
  public timezonesList: TimezoneListingDTO[];
  public pmsChargeCollection: {id: string, name: string }[] = [
    {"id": "No", "name": "No"},
    {"id": "PerOrder", "name": "Per Order"},
    {"id": "PerItem", "name": "Per Item"},
  ];
  public checkInTypeCollection: {id: string, name: string }[] = [
    {"id": "None", "name": "None"},
    {"id": "OnlineCheckIn", "name": "Online Check-In"},
    {"id": "SelfCheckIn", "name": "Self Check-In"},
    {"id": "OnlineCheckInAndSelfCheckIn", "name": "Online Check-In + Self Check-In"},
    {"id": "InformationOnly", "name": "Information Only"},
  ];
  @ViewChild("crsStaysSourceDropdown") private crsStaysSourceDropdown: DropdownComponent;
  public crsStaysSourceCollection: {id: string, name: string }[] = [
    {"id": "IntegrationServer", "name": "Integration Server"},
    {"id": "BGuest", "name": "B-Guest"},
  ];

  @ViewChild(CurrencyDropdownComponent) currencyDropdownComponent: CurrencyDropdownComponent;
  @ViewChild(TimezoneDropdownComponent) timezoneDropdownComponent: TimezoneDropdownComponent;
  @ViewChild("pmsChargeDropdown") private pmsChargeDropdown: DropdownComponent;
  @ViewChild("checkInTypeDropdown") private checkInTypeDropdown: DropdownComponent;

  
  /** app-settings ctor */
  constructor(private entityService: EntityService,
    private listingsService: ListingsService,
    private authService: AuthenticationService,
    private injector: Injector) {
    this.appLayoutList = Object.keys(AppLayout);
    this.popupConfiguration = new PopupConfig("Use default airports by location", ["This action will remove selected airports and use default ones based on entity location."]);
  }

  ngOnInit(): void {
    this.setComponentDimmerVisibility(true);
    this.getStatic();
  }

  ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    if (this.rxSubscription) {
      this.rxSubscription.unsubscribe();
    }
  }

  public observablePmsCharge(): Observable<any> {
    return of(this.pmsChargeCollection);
  }

  public pmsChargeDropdownObservable = (): Observable<any> => {
    return of(this.pmsChargeCollection);
  }

  public pmsChargeDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  public observableCheckInType(): Observable<any> {
    return of(this.checkInTypeCollection);
  }

  public checkInTypeDropdownObservable = (): Observable<any> => {
    return of(this.checkInTypeCollection);
  }

  public checkInTypeDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  public crsStaysSourceDropdownObservable = (): Observable<any> => {
    return of(this.crsStaysSourceCollection);
  }

  public crsStaysSourceDropdownNameResolver(item: {id: string, name: string }) {
    return item.name;
  }

  private getStatic() {
    this.rxSubscription.add(this.observablePmsCharge()
      .pipe(finalize(() => { }))
      .subscribe((response) => {
        this.pmsChargeCollection = response;
      }));
    this.rxSubscription.add(this.observableCheckInType()
      .pipe(
        finalize(() => {
           this.getAirportsListings();
        })
      )
      .subscribe((response) => {
        this.checkInTypeCollection = response;
      }));
  }

  private getAirportsListings() {
    this.rxSubscription.add(this.listingsService.getAirports()
      .pipe(
        finalize(() => {
          this.getCurrencies();
        })
      )
      .subscribe((airports) => {
        this.airportsList = airports;
      }));
  }

  private getCurrencies() {
    this.rxSubscription.add(this.listingsService.getCurrencies()
      .pipe(
        finalize(() => {
          this.getTimezones();
        })
      )
      .subscribe((currencies) => {
        this.currenciesList = currencies;
      }));
  }

  private getTimezones() {
    this.rxSubscription.add(this.listingsService.getTimezones()
      .pipe(
        finalize(() => {
          this.getAppConfigurations();
        })
      )
      .subscribe((timezones) => {
        this.timezonesList = timezones;
      }));
  }

  private getAppConfigurations() {
    const entityId = this.authService.getUserSelectedEntityId();
    if (entityId) {
      this.rxSubscription.add(this.entityService.getAppConfigurations()
        .pipe(
          finalize(() => {
            this.setComponentDimmerVisibility(false);
            if (this.multipleSelectDropdownComponent) {
              this.multipleSelectDropdownComponent.setSelectedValue(this.appConfigurationDto.airports);
            }
          }))
        .subscribe((appConfig) => {
          this.appConfigurationDto = appConfig;
        }));
    }
  }

  /**
   * Airport dropdown name resolver
   * @param item Airport object 
   */
  public airportNameResolver(item: AirportListingDTO): string {
    if (!item) {
      return null;
    }

    return item.name;
  }

  /**
   * Airport dropdown value resolver
   * @param item Airport object 
   */
  public airportValueResolver(item: AirportListingDTO): number {
    if (!item) {
      return null;
    }

    return item.id;
  }

  public fileChange(event: EventTarget) {
    this.setComponentDimmerVisibility(true);

    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;

    const formData: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.entityService.updateAppConfigurationsUploadedLogo(formData)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((appConfig) => {
        this.appConfigurationDto = appConfig;
      });
  }

  public onCheckboxChange(value: boolean) {
    this.save();
  }

  public onCheckboxGroupSettingsChange(value: boolean) {
    //clear the group settings before save
    if (value) {
      this.appConfigurationDto.layout = AppLayout.List;
      this.appConfigurationDto.timezone = null;
      this.appConfigurationDto.logo = null;
      if (this.timezoneDropdownComponent) {
        this.timezoneDropdownComponent.setSelectedValue(this.appConfigurationDto.timezone);
      }
    }
    this.save();
  }

  public onCheckboxDisabledChange(value: boolean) {
    if (value) {
      this.popupComponent.show();
    } else {
      this.save();
    }
  }

  public onPopupApprove($event: JQuery<HTMLElement>) {
    this.save();
  }

  public onPopupDeny($event: JQuery<HTMLElement>) {
    this.appConfigurationDto.useLocationAirport = false;
  }

  public onChangeAirports(value: any, item: AirportListingDTO) {
    this.save();
  }

  public save() {
    if (this.appConfigurationDto.hasBGuestNotifications && !this.appConfigurationDto.noniusTenant) {
      this.notifierService.info('You need to provider Tenant Backend to work with B-Guest Notifications', 'Missing Tenant Backend');
    }

    this.setComponentDimmerVisibility(true);
    if (this.multipleSelectDropdownComponent) {
      this.multipleSelectDropdownComponent.hide();
    }
    if (this.currencyDropdownComponent) {
      this.currencyDropdownComponent.hide();
    }
    if (this.timezoneDropdownComponent) {
      this.timezoneDropdownComponent.hide();
    }
    if (this.pmsChargeDropdown) {
      this.pmsChargeDropdown.hide();
    }
    if (this.checkInTypeDropdown) {
      this.checkInTypeDropdown.hide();
    }
    if (this.crsStaysSourceDropdown) {
      this.crsStaysSourceDropdown.hide();
    }

    this.entityService.updateAppConfigurations(this.appConfigurationDto)
      .pipe(
        finalize(() => {
          this.setComponentDimmerVisibility(false);
          if (this.multipleSelectDropdownComponent) {
            this.multipleSelectDropdownComponent.setSelectedValue(this.appConfigurationDto.airports);
          }
          if (this.currencyDropdownComponent && this.appConfigurationDto.currency) {
            this.currencyDropdownComponent.setSelectedValue(this.appConfigurationDto.currency.id);
            this.currencyDropdownComponent.hide();
          }
          if (this.timezoneDropdownComponent) {
            this.timezoneDropdownComponent.setSelectedValue(this.appConfigurationDto.timezone);
            this.timezoneDropdownComponent.hide();
          }
          if (this.pmsChargeDropdown) {
            this.pmsChargeDropdown.setSelectedValue(this.appConfigurationDto.ordersHavePMSCharge)
            this.pmsChargeDropdown.hide();
          }
          if (this.checkInTypeDropdown) {
            this.checkInTypeDropdown.setSelectedValue(this.appConfigurationDto.checkInType)
            this.checkInTypeDropdown.hide();
          }
          if (this.crsStaysSourceDropdown) {
            this.crsStaysSourceDropdown.setSelectedValue(this.appConfigurationDto.crsStaysSource)
            this.crsStaysSourceDropdown.hide();
          }
        }))
      .subscribe((appConfig) => {
        this.appConfigurationDto = appConfig;
      });
  }

  public disabledGroupSettings(): boolean {
    if (!this.appConfigurationDto) return true;

    return !this.appConfigurationDto.overrideGroupSettings &&
      (this.authService.getUserSelectedEntity() && this.authService.getUserSelectedEntity().type === EntityType.GROUP_MEMBER);
  }

  public showIfGroupMember() {
    return this.authService.getUserSelectedEntity() && this.authService.getUserSelectedEntity().type === EntityType.GROUP_MEMBER;
  }

  public showRemoveBtn(): boolean {
    if (this.appConfigurationDto && this.appConfigurationDto.logo && this.appConfigurationDto.logo.url) {
      return true;
    }
    return false;
  }

  public onClickRemove(event: Event) {
    event.stopPropagation();

    if (!this.appConfigurationDto) {
      return;
    }

    this.setComponentDimmerVisibility(true);
    this.entityService.updateAppConfigurationsRemoveLogo(this.appConfigurationDto.logo)
      .pipe(finalize(() => this.setComponentDimmerVisibility(false))).subscribe((appConfig) => {
        this.appConfigurationDto = appConfig;
      });
  }

  // Component 
  private setComponentDimmerVisibility(visible: boolean) {
    const dimmerElement =
      $(this.componentDimmer.nativeElement)
        .dimmer({
          duration: { show: 400, hide: 800 }
        }
        );

    if (visible) {
      this.fetchingComponentData = true;
      dimmerElement.dimmer('show');
    } else {
      dimmerElement.dimmer('hide');
      this.fetchingComponentData = false;
    }
  }

  public resolveValue(entry: string) {
    return entry;
  }

  public resolveChecked = (entry: AppLayout): true | null => {
    if (!this.appConfigurationDto) return null;

    return this.appConfigurationDto.layout === entry ? true : null;
  }

  public resolveLabelName(entry: string) {
    return entry;
  }

  public onCurrencySelectedChange(value: any) {
    if (this.appConfigurationDto) {
      if (value) {
        this.appConfigurationDto.currency = new CurrencyDTO(value);
      } else {
        this.appConfigurationDto.currency = null;
      }
      this.save();
    }
  }

  public onTimezoneSelectedChange(value: TimezoneListingDTO) {
    if (this.appConfigurationDto) {
      if (value) {
        this.appConfigurationDto.timezone = value.id;
      } else {
        this.appConfigurationDto.timezone = null;
      }      
      this.save();
    }
  }

  public onSaveEvent(event: any) {
    this.save()
  }
}

