export enum Feature {
    
    MobileKey = "Mobile Key",
    
    Bill = "Bill",
    
    Softphone = "Softphone",
    
    TvRemote = "TV Remote",

    RoomControl = "Room Control",

    Outlet = "Outlet",

    MyStaysPreCheckIn = "Pre Check-In (MyStays)",

    MyStaysSelfCheckIn = "Self Check-In (MyStays)",

}