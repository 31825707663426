<!-- Info area, grid -->
<div class="ui blurring dimmable grid">

  <!--Begin Container-->
  <div class="row" *ngIf="appConfigurationDto">
    <!-- left side -->
    <div class="eight wide column">

      <div class="row">
        <h4 class="ui top attached header">
          <div><i class="cog icon"></i>Main</div>
        </h4>

        <div class="ui attached segment">
          <div class="ui grid" *ngIf="this.authService.userIsAdminOrManager()">
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Tenant Backend </div>
                <sui-input [placeholder]="'Tenant Backend'" [(inputValue)]="appConfigurationDto.noniusTenant"
                           (onChangeComplete)="save()" (keydown.enter)="save()">
                </sui-input>
              </div>
              <div class="column">
                <div class="ui tiny header">Backend Nonius</div>
                <sui-input [placeholder]="'Backend Nonius'" [(inputValue)]="appConfigurationDto.backendNonius"
                           (onChangeComplete)="save()" (keydown.enter)="save()">
                </sui-input>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Currency </div>
                <currency-dropdown [collection]="currenciesList" (selectedChange)="onCurrencySelectedChange($event)"
                                   [(selectedValue)]="appConfigurationDto.currency">
                </currency-dropdown>
              </div>
              <div class="column">
                <div class="ui tiny header">Orders Have PMS Charge</div>
                <sui-dropdown #pmsChargeDropdown defaultText="Select option" [collection]="pmsChargeCollection"
                              [(selectedValue)]="appConfigurationDto.ordersHavePMSCharge"
                              (valueChange)="onSaveEvent($event)" [observable]="pmsChargeDropdownObservable"
                              [nameResolver]="pmsChargeDropdownNameResolver" loadDataImmediately="true"
                              forceSelectedValueOnStart="true"></sui-dropdown>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Check-In Type</div>
                <sui-dropdown #checkInTypeDropdown defaultText="Select option" [collection]="checkInTypeCollection"
                              [(selectedValue)]="appConfigurationDto.checkInType"
                              (valueChange)="onSaveEvent($event)" [observable]="checkInTypeDropdownObservable"
                              [nameResolver]="checkInTypeDropdownNameResolver" loadDataImmediately="true"
                              forceSelectedValueOnStart="true"></sui-dropdown>
              </div>
              <div class="column">
                
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column ui form">
                <div class="fields">
                  <div class="field">
                    <div class="ui tiny header">Check-In Time</div>
                    <time-picker [(inputValue)]="appConfigurationDto.checkInTime"
                                  (onChangeComplete)="onSaveEvent($event)">
                    </time-picker>
                  </div>
                </div>
              </div>
              <div class="column ui form">
                <div class="fields">
                  <div class="field">
                    <div class="ui tiny header">Check-Out Time </div>
                    <time-picker [(inputValue)]="appConfigurationDto.checkOutTime"
                                  (onChangeComplete)="onSaveEvent($event)">
                    </time-picker>
                  </div>
                </div>
              </div>
            </div>

            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Crs Stays Source</div>
                  <sui-dropdown #crsStaysSourceDropdown defaultText="Select option" [collection]="crsStaysSourceCollection"
                                [(selectedValue)]="appConfigurationDto.crsStaysSource"
                                (valueChange)="onSaveEvent($event)" [observable]="crsStaysSourceDropdownObservable"
                                [nameResolver]="crsStaysSourceDropdownNameResolver" loadDataImmediately="true"
                                forceSelectedValueOnStart="true" [isClearable]="false" [showFirstLoading]="false">
                  </sui-dropdown>
              </div>
              <div class="column">
                
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="field">
                  <sui-checkbox [(inputValue)]="appConfigurationDto.applyTimezoneOnReservationDatetimeFields" [isSlider]="false"
                    [labelName]="'Apply timezone on reservation datetime fields'"
                    (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="field">
                  <sui-checkbox [(inputValue)]="appConfigurationDto.applyTimezoneOnStayDatetimeFields" [isSlider]="false"
                    [labelName]="'Apply timezone on stay datetime fields'"
                    (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="field">
                  <sui-checkbox [(inputValue)]="appConfigurationDto.overrideStayCheckOutTime" [isSlider]="false"
                    [labelName]="'Override stay check-out time'"
                    (onChangeValue)="onCheckboxChange($event)">
                  </sui-checkbox>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <h4 class="ui top attached header">
          <div><i class="clone icon"></i>Group</div>
        </h4>

        <div class="ui attached segment">
          <div class="ui grid" *ngIf="this.authService.userIsAdminOrManager()">
            <div class="row" *ngIf="showIfGroupMember()">
              <div class="column">
                <sui-checkbox [(inputValue)]="appConfigurationDto.overrideGroupSettings" [isSlider]="false"
                  [labelName]="'Override group settings'" (onChangeValue)="onCheckboxGroupSettingsChange($event)">
                </sui-checkbox>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="fields">
                  <div class="eight wide field" [class.disabled]="disabledGroupSettings()">
                    <div class="ui tiny header">Layout</div>
                    <radio [collection]="appLayoutList" (onSelectChanged)="save()"
                      [labelNameResolver]="resolveLabelName" [valueResolver]="resolveValue"
                      [checkedResolver]="resolveChecked" [(selectedValue)]="appConfigurationDto.layout">
                    </radio>
                  </div>
                  <div class="eight wide field" [class.disabled]="disabledGroupSettings()"
                    *ngIf="!this.authService.userIsManager()">
                    <div class="ui tiny header">Timezone </div>
                    <timezone-dropdown [collection]="timezonesList" (selectedChange)="onTimezoneSelectedChange($event)"
                      [(selectedValue)]="appConfigurationDto.timezone">
                    </timezone-dropdown>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="column ui form">
                <div class="fields">
                  <!--NewLogo-->
                  <div class="eight wide field" [class.disabled]="disabledGroupSettings()">
                    <div class="ui tiny header">Logo</div>
                    <div class="" *ngIf="!showRemoveBtn()">
                      <label for="fileInput" class="ui mini green button">
                        <i class="ui upload icon"></i>
                        Upload
                      </label>
                      <input type="file" (change)="fileChange($event)" name="logo" style="display: none;"
                        id="fileInput" />
                    </div>
                    <div style="margin: 10px 5px 10px 0">
                      <img style="margin: 0 0 5px 5px" class="ui tiny image" src="{{appConfigurationDto?.logo?.url}}">
                      <label class="ui mini red button" *ngIf="showRemoveBtn()" (click)="onClickRemove($event)">
                        <i class="ui trash alternate icon"></i>
                        Remove
                      </label>
                    </div>
                  </div>
                  <!--Airports-->
                  <div class="eight wide field">
                    <div class="ui tiny header">Airports</div>
                    <div class="ui grid">
                      <div class="row">
                        <div class="column">
                          <sui-checkbox [(inputValue)]="appConfigurationDto.useLocationAirport" [isSlider]="false"
                            [labelName]="'Use default airports to entity\'s location'"
                            (onChangeValue)="onCheckboxDisabledChange($event)">
                          </sui-checkbox>
                          <popup [(configuration)]="popupConfiguration" (onDeny)="onPopupDeny($event)"
                            (onApprove)="onPopupApprove($event)">
                          </popup>
                        </div>
                      </div>

                      <div class="row">
                        <div class="sixteen wide column ui form">
                          <div class="fields">
                            <div class="sixteen wide field" [class.disabled]="appConfigurationDto.useLocationAirport">
                              <multiple-select-dropdown [collection]="airportsList"
                                (onChangeValue)="onChangeAirports($event, null)" [nameResolver]="airportNameResolver"
                                [valueResolver]="airportValueResolver" [(selectedValue)]="appConfigurationDto.airports"
                                [search]="true">
                              </multiple-select-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- right side -->
    <div class="eight wide column">

      <div class="row">
        <h4 class="ui top attached header">
          <div><i class="th icon"></i>Features</div>
        </h4>

        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Has DoorLock</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasDoorLock"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
                <div class="ui tiny header">Has Tv</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasTv" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Send Requests To Hub</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.sendRequestsToHub"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
                <div class="ui tiny header">Has B-Guest Notifications</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasBGuestNotifications"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Has WebApp</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasWebApp" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
                <div class="ui tiny header">Has Shopping Cart</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasShoppingCart"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Print Orders</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.printOrders"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">iOS devices have FCM</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.iosHasFCM" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Sync POS with Hub</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.syncPOSWithHub"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>

              <div class="column">
                <div class="ui tiny header">Has Room Control</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasRoomControl"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Sync Chats with Hub</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.syncChatsWithHub"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">Sync Activities with Hub</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.syncActivitiesWithHub"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">Has Dynamic Links for Check-In Online</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasDynamicLinksForCheckInOnline"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">Has Dynamic Links for WebViews</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.hasDynamicLinksForWebViews"
                  (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
            <div class="doubling two column row">
              <div class="column">
                <div class="ui tiny header">FCM for Flutter</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.fcmForFlutter" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
              <div class="column">
                <div class="ui tiny header">Use Rooms Whitelist</div>
                <sui-checkbox [(inputValue)]="appConfigurationDto.useRoomsWhitelist" (onChangeValue)="onCheckboxChange($event)">
                </sui-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <h4 class="ui top attached header">
          <div><i class="ellipsis horizontal icon"></i>Additional Configs</div>
        </h4>

        <div class="ui attached segment" *ngIf="!this.authService.userIsManager()">
          <div class="ui grid">
            <div class="sixteen wide column">
              <div class="ui form">
                <div class="field">
                  <sui-textarea [placeholder]="'Additional Configs'" [rows]="6"
                    [(inputValue)]="appConfigurationDto.additionalConfigs" (onChangeComplete)="save()">
                  </sui-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <!--Finish Container-->
  <!-- dimmer -->
  <div #componentDimmer class="ui inverted dimmer">
    <div class="ui loader"></div>
  </div>
</div>
