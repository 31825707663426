import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sui-checkbox',
  templateUrl: 'sui-checkbox.component.html',
  styleUrls: ['sui-checkbox.component.css']
})

export class CheckboxComponent implements OnInit {

  @Input() public labelName: string;
  @Output() onChangeValue: EventEmitter<boolean> = new EventEmitter();
  @Input() isSlider: boolean = true;

  /**
   * inputValue [()]
   */
  inputValueLocal: boolean;
  @Input()
  set inputValue(val) {
    this.inputValueLocal = val;
    this.inputValueChange.emit(this.inputValueLocal);
  }

  get inputValue() {
    return this.inputValueLocal;
  }
  @Output() inputValueChange: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
  }

  private errorHandle(error: any): void {
    console.log(error);
  }

  public onChange(event: Event) {
    if (this.onChangeValue) {
      this.onChangeValue.emit(this.inputValue);
    }
  }

}
