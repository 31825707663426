export class FullMembershipConfigurationDTO {
    
    version: number;

    enabled: boolean;

    defaultTenant: string;
    
    buttonIcon: string;
    
    authenticationUrl: string;
    
    statusBarLogoUrl: string;
    
    cardTemplateUrl: string;

    iconUrl: string;

    loginFeaturesEnabled: boolean;

    loginFeaturesMandatory: string;
}
